/*
    Basics
 */

.page-container {
    position: relative;
    min-height: 100vh;
}

.content-wrap {
    padding-bottom: 180px;    /* Footer height */
}

.fimo-background {
    background-color: rgb(242, 242, 242);
}

.fimo-content-page {
    background-color: white;
    margin-right: 75px;
    margin-left: 75px;
    max-width: 100%;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
    margin-right:0;
    margin-left:0;
}

.dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.delete-dialog {
    height: fit-content;
    width: fit-content;
    top: 30%;
    text-align: center;
    border-radius: 20px;
}

/*
    Primary buttons
 */
.btn-primary {
    background-color: rgb(80, 160, 82);
    border-color: rgb(80, 160, 82);
}
.btn-primary:hover {
    background-color: rgb(90, 170, 92);
}
.btn-primary:disabled, .btn-primary-fimo.disabled {
    background-color: rgb(80, 160, 82);
    opacity: 0.65;
}
.btn-primary.active, .btn-primary-fimo:active {
    box-shadow: 0 0 0 0.2rem rgba(80, 160, 82, 0.5);
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    background-color: rgb(100, 180, 102);
    border-color: #005cbf;
}
/*
    Primary button outlined
 */
.btn-outline-primary {
    color: rgb(80, 160, 82);
    border-color: rgb(80, 160, 82);
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: rgb(80, 160, 82);
    border-color: rgb(80, 160, 82);
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: rgb(90, 170, 92);
    border-color: rgb(90, 170, 92);
}

.btn-outline-form {
    color: #0078be;
    margin-right: 20px;
    margin-bottom: 30px;
    font-size: 0.8em;
}

.btn-outline-form-slim {
    color: #0078be;
    margin-right: 20px;
    margin-bottom: 5px;
    font-size: 0.8em;
}

.btn-outline-policycard {
     color: #0078be;
     margin-left: 8px;
     margin-bottom: 30px;
     font-size: 0.8em;
     padding: 3px;
 }


.btn-outline-table {
    color: #0078be;
    margin-left: 8px;
    margin-bottom: 5px;
    font-size: 0.8em;
}


.btn-outline-form div {
    font-size: 2.0em;
}

/*
    Link buttons
 */
.btn-link {
    font-weight: 400;
    color: rgb(90, 170, 92);
    text-decoration: none;
}
.btn-link-small {
    font-weight: 400;
    color: rgb(200, 200, 200);
    text-decoration: none;
    font-size: 0.9em;
}
.btn-link:hover, .btn-link-small:hover {
    color: rgb(80, 160, 92);
    text-decoration: underline;
}
.btn-link:focus, .btn-link.focus, .btn-link-small:focus, .btn-link-small.focus {
    color: rgb(80, 16, 92);;
    text-decoration: underline;
    box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled, .btn-link-small:disabled, .btn-link-small.disabled {
    color: rgb(90, 170, 92);
    opacity: 0.65;
    pointer-events: none;
}
/*
    Sidebar link buttons
 */
.btn-link-sidebar {
    font-weight: 400;
    color: white;
    text-decoration: none;
    display: block;
    fontSize: 1.2em;
    font-weight: bold;
    marginBottom: 20px;
}
.btn-link-sidebar.disabled, .btn-link-sidebar:disabled {
    color: rgb(120, 200, 122);
}
.btn-link-sidebar:hover {
    color: rgba(255, 255, 255, 0.65);
}
.btn-link-sidebar:focus, .btn-link.focus {
    color: rgb(90, 170, 92);
    box-shadow: none;
}
/*
    Menu link buttons
 */
.btn-link-menu {
    font-weight: 400;
    color: rgb(0, 120, 190);
    text-decoration: none;
}
.btn-link-menu-selected {
    font-weight: 400;
    color: rgb(0, 120, 190);
    text-decoration: underline;
}
.btn-link-menu-selected:focus, .btn-link-menu-selected.focus, .btn-link-menu:focus, .btn-link-menu.focus {
     font-weight: 400;
     color: rgb(0, 120, 190);
     text-decoration: underline;
     box-shadow: none;
 }
.btn-link-menu-selected:hover, .btn-link-menu-selected.hover, .btn-link-menu:hover, .btn-link-menu.hover {
    font-weight: 400;
    color: rgb(80, 16, 92);
    box-shadow: none;
}




/*
    Text colors
 */
.text-fimo-green{
    color: rgb(80, 160, 82);
}
.text-fimo-blue{
    color: rgb(0, 120, 190);
}
/*
    Headlines
 */
h4 {
    color: rgb(80, 160, 82);
    margin-bottom: 50px;
}
.headline-with-btn {
    color: rgb(80, 160, 82);
    margin-bottom: 50px;
}
/*
    Policy details header
 */
.details-header {
    padding-left: 30px;
    margin-bottom: 32px;
    margin-top: 10px;
    border-Bottom: 2px solid rgb(80, 160, 82);
}
/*
    Cards
 */
.form-card{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}


/*
    Front page
 */

.title1 {
    color: rgb(80, 160, 82);
    margin-bottom: 10px;
    font-size: 1.25em;
    font-weight: 300;
}

.title2 {
    color: rgb(80, 160, 82);
    margin-bottom: 25px;
    font-size: 1.5em;
    font-weight: 300;
}

.title2b {
    color: rgb(80, 160, 82);
    margin-bottom: 5px;
    font-size: 1.5em;
    font-weight: 600;
}

.title3 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.2em;
    font-weight: 600;
}

.line1 {
    background-color: rgb(80, 160, 82);
    margin-bottom: 40px;
}

.line2 {
    background-color: rgb(80, 160, 82);
    height: 1px;
    margin-top: 80px;
}


/*
    Footer
 */

.footer {
    padding-top: 50px;
    background-color: #3f3e3e;

    position: absolute;
    bottom: 0;
    width: 100%;
    height: 180px;
}

.footer-button {
    color: white;
    text-decoration: none;
}

.footer-button:focus, .footer-button.focus {
    color: white;
    text-decoration: none;
}
.footer-button:hover, .footer-button.hover {
    color: rgb(220, 220, 220);
    text-decoration: none;
}


/*
    General texts
 */

div.general-text p {
    margin-top: 15px;
    padding-bottom: 15px;
}


div.general-text h5 {
    margin-top: 35px;
    padding-bottom: 5px;
}

div.general-text h6 {
    margin-top: 20px;
    padding-bottom: 5px;
}

.sidebar {
    width: 17%;
    min-height: 100%;
}

.sidebar-collapsed {
    width: 3%;
    min-height: 100%;
}

.main-container {
    width: 83%;
}

.main-container-expanded {
    width: 97%;
}

.page-container {
    min-width: 768px;
}

.menu-toggle {
    margin-top: -100px;
    color: white;
    padding: 0px;
}

.menu-image {
    height: 30px;
    width: 30px;
}

.menu-toggle-expanded {
    margin-right: 10%;
}

.menu-toggle-collapsed {
    margin-right: 20px;
}

.menu-toggle:hover {
    color: rgba(255, 255, 255, 0.65);
}

.text-wrap {
    word-break: break-all;
    moz-hyphens: auto;
    o-hyphens: auto;
    webkit-hyphens: auto;
    ms-hyphens: auto;
    hyphens: auto;
}

@media screen and (max-width: 768px) {
    h2 {
        font-size: 20px;
    }

    p, span, td {
        font-size: 15px;
    }

    .btn-link-sidebar {
        font-size: 15px;
    }

    .sidebar {
        width: 30%;
        min-height: 100%;
    }

    .sidebar-collapsed {
        width: 5%;
        min-height: 100%;
    }

    .main-container {
        width: 70%;
    }

    .main-container-expanded {
        width: 95%;
    }

    .menu-toggle {
        margin-top: -100px;
        color: white;
        padding: 0px;
    }

    .menu-toggle-collapsed {
        margin-left: -10px;
    }

    .menu-image {
        height: 20px;
        width: 20px;
    }
}

/*
  Tooltips
*/

.permission-tooltip {
   position: relative;
   display: inline-block;
}

.permission-tooltip .tooltipText {
    visibility: hidden;
    background-color: #555;
    border-radius: 6px;
    min-width: 17vw;
  
    /* Position the tooltip text */
    position: absolute;
    text-align: left;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 1;
    transition: opacity 0.3s;

    /* max height with overflow */
    max-height: 30vh;
    overflow-y: auto;
  }
  
/* Show the tooltip text when you mouse over the tooltip container */
.permission-tooltip:hover .tooltipText,
.tooltipText:hover {
    visibility: visible;
    opacity: 1;
    /* show tooltip immediately on hover */
    transition-property: visibility;
    transition-delay: 0s;
}

.permission-tooltip:not(hover) .tooltipText {
    /* hide tooltip after 0.3s */
    transition-property: visibility;
    transition-delay: 0.3s;
}